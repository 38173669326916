<template>
  <div class="seleTest xi" id="app">
    <hd-com></hd-com>
    <div class="content-wrap">
      <div class="content">
        <div class="top-div flex-start">
          <div class="left-img"></div>
          <div class="top-title">{{ QuestionnaireResult.questionnaire.name }}</div>
        </div>
        <!-- 选择测试人 -->
        <div class="userTestRes-div flex-center">
          <div class="userTestRes-box flex-center-columns">
            <!-- <div class="top-img flex-start">
                            <img src="../../assets/img/result.png" />
                            <div class="title">测试结果</div>
                        </div> -->
            <div class="content-center">
              <!-- <div class="share-box flex-end">
                                <div class="left">
                                    <image class="img-icon" src="../../static/share.png" mode=""></image>分享
                                </div>
                                <div class="" @click="goPdfDetail">
                                    <image class="img-icon" src="../../static/file-pdf.png" mode=""></image>导出PDF
                                </div>
                            </div> -->
              <div class="res-box">
                <div style="width: 90%; margin: 5px auto;border-bottom: 1px solid #e0e0e0; padding-bottom: 10px">
                  <div class="flexlr" style="align-items: center; margin: 5px auto">
                    <div style="font-size: 16px; font-weight: bold; display: flex; align-items: center">
                      <div class="ring"></div>
                      <div style="margin-left: 10px">{{ QuestionnaireResult.customerName }}</div>
                      <div class="" style="margin-left: 10px">
                        {{ QuestionnaireResult.customerSex ? "女" : "男" }}
                      </div>
                      <div class="" style="margin-left: 10px">
                        {{ QuestionnaireResult.ageName }}
                      </div>
                      <div class="" style="margin-left: 10px">
                        {{ QuestionnaireResult.customerPhone ? QuestionnaireResult.customerPhone.substr(0, 3) + "****" + QuestionnaireResult.customerPhone.substr(7) : "" }}
                      </div>
                    </div>
                  </div>
                  <div class="" style="font-size: 15px; color: #666666;  margin: 5px auto; margin-left: 25px; ">
                    您的基础病：
                    <span>{{ QuestionnaireResult.diseaseName?QuestionnaireResult.diseaseName:'暂未填写' }}</span>
                  </div>
                </div>
                <div class="c1 flex-start align-s">
                  <div class="left-title"><img src="../../assets/img/result.png" mode="" style="width: 15px; height: 15px; margin-right: 5px" />您的测试结果是：</div>
                  <div class="score-right">
                    <div v-if="resultScoreList1.length > 0" class="score-box flex-space-between" v-for="(item, index) in resultScoreList1" :key="index">
                      <div class="desc color1">
                        {{ item.name }}<span class="fen-box bg1"> {{ item.scores }} <span class="fen">分</span></span>
                      </div>
                      <div class="btn bg3" v-if="QuestionnaireResult.questionnaireDetermines[0].scores > 0" @click="toDetail(item.questionnaireResultId)">查看详情</div>
                    </div>
                    <div v-if="resultScoreList1.length == 0" class="score-box flex-space-between">
                      <div class="desc color1">无结果</div>
                    </div>
                  </div>
                </div>
                <div class="c2 flex-start align-s">
                  <div class="left-title">详细得分：</div>
                  <div class="score-right">
                    <div v-if="resultScoreList.length > 0" class="score-box flex-space-between" v-for="(item, index) in resultScoreList" :key="index">
                      <div class="desc color2">
                        {{ item.name }}
                        <span class="fen-box bg2"> {{ item.scores }} <span class="fen">分</span></span>
                      </div>
                      <div class="btn color2 border1" v-if="QuestionnaireResult.questionnaireDetermines[0].scores > 0" @click="toDetail(item.questionnaireResultId)">查看详情</div>
                    </div>
                    <div v-if="resultScoreList.length == 0" class="score-box flex-space-between">
                      <div class="desc color2">无得分</div>
                    </div>
                  </div>
                </div>
                <div class="c3 flex-space-between">
                  <img src="../../assets/img/tips.png" mode="" style="width: 15px; height: 16px; margin-right: 5px; margin-top: 2px" />
                  <div class="tips">本测试仅作为诊断和治疗的参考。对于已经确诊的疾病，应按照医嘱积极治疗。所提及的内容仅作为生活保健的咨询建议，如遇不适请及时就医。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <rt-bar></rt-bar>
  </div>
</template>

<script>
import hdCom from "../../components/header";
import ftCom from "../../components/footer";
// import rtBar from "../../components/right";
import { Test } from "../../components/company/test";
export default {
  name: "selfTest",
  components: {
    hdCom,
    ftCom,
    // rtBar,
  },
  data() {
    var test = new Test(this.TokenClient, this.Services.Statics);
    return {
      testDomain: test,
      userInfo: this.$store.state.localData.userInfo,
      resId: this.$route.query.resId,
      answerUserId: this.$route.query.answerUserId,
      QuestionnaireResult: {
        questionnaire: {
          name: "",
        },
        questionnaireDetermines: [
          {
            scores: 0,
          },
        ],
      },
      qingXiang: [],
      tiZhi: [
        {
          name: "",
        },
      ],
      result: "",
      resultList: [],
      categories: [],
      series: [],
      InfoSummarize: "",
      adviseInfo: {
        name: "",
        infos: [
          {
            info: "",
          },
        ],
      },
      indicator: [],
      resultScoreList: [],
      resultScoreList1: [],
      resultScoreList2: [],
      pdfUrl: "",
      QRCodeUrlPDF: "",
    };
  },
  mounted() {
    this.$bus.$emit("isShowChangeSize");
    this.$nextTick(() => {
      this.getResult();
    });
  },
  methods: {
    goPdfDetail() {
      let params = [];
      params.push(this.Id);
      this.$http
        .post(getExport + "pdf", params, {
          custom: {
            auth: true,
          },
        })
        .then(res => {
          if (res.data.code == 200) {
            this.goPdfTast(res.data.data.id);
          }
        })
        .catch(err => {});
    },

    goPdfTast(taskId) {
      uni.showLoading({
        title: "导出中...",
      });
      this.$http
        .get(getExportTasks + taskId, {
          custom: {
            auth: true,
          },
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data.statu == 0) {
              setTimeout(() => {
                this.goPdfTast(taskId);
              }, 1000);
            } else if (res.data.data.statu == 1 && res.data.data.index == -1) {
              uni.hideLoading();
              this.pdfUrl = this.$http.config.baseURL + "statics" + res.data.data.path;
              var url = "?url=" + this.pdfUrl + "&pixel=178";
              this.$http
                .get(getAPPQRCode + url, {
                  custom: {
                    auth: true,
                  },
                })
                .then(res => {
                  if (res.data.code == 200) {
                    uni.hideLoading();
                    this.$refs.shopDialog.open();
                    this.QRCodeUrlPDF = this.$http.config.baseURL + "statics" + res.data.data;
                  }
                })
                .catch(err => {});
            } else if (res.data.data.statu == -1) {
              uni.hideLoading();
              uni.showToast({
                title: "转pdf失败，请稍后重试",
                duration: 2000,
              });
            } else if (res.data.data.statu == 0 && res.data.data.index > 0) {
              let _this = this;
              uni.hideLoading();
              uni.showModal({
                title: "提示",
                content: "当前排队人数为" + res.data.data.index,
                success: function (res) {
                  if (res.confirm) {
                    _this.goPdfTast(taskId);
                  } else if (res.cancel) {
                    console.log("用户点击取消");
                  }
                },
              });
            }
          }
        })
        .catch(err => {
          uni.hideLoading();
        });
    },
    /**
     * 获取测试结果
     */
    getResult() {
      var _this = this;
      this.testDomain.getUserResultById(
        this.resId,
        res => {
          this.QuestionnaireResult = res.data;
          if (this.QuestionnaireResult.customerAge == 0) {
            this.QuestionnaireResult.ageName = "6岁以下";
          } else if (this.QuestionnaireResult.customerAge == 1) {
            this.QuestionnaireResult.ageName = "7-12岁";
          } else if (this.QuestionnaireResult.customerAge == 2) {
            this.QuestionnaireResult.ageName = "13-17岁";
          } else if (this.QuestionnaireResult.customerAge == 3) {
            this.QuestionnaireResult.ageName = "18-45岁";
          } else if (this.QuestionnaireResult.customerAge == 4) {
            this.QuestionnaireResult.ageName = "46-69岁";
          } else if (this.QuestionnaireResult.customerAge == -1) {
            this.QuestionnaireResult.ageName = data.data.primarySymptomMinAge + "-" + data.data.primarySymptomMaxAge + "岁";
          } else {
            this.QuestionnaireResult.ageName = "69岁以上";
          }
          let resultScoreListTop = [];
          _this.resultScoreList = [];
          if (_this.QuestionnaireResult.questionnaireDetermines.length > 0) {
            _this.resultScoreList = _this.QuestionnaireResult.questionnaireDetermines.sort((a, b) => {
              return b.scores - a.scores;
            });
            for (var i = 0; i < _this.resultScoreList.length; i++) {
              if (_this.resultScoreList[0].scores == _this.resultScoreList[i].scores) {
                resultScoreListTop.push(_this.resultScoreList[i]);
              }
            }
          }
          _this.resultScoreList1 = resultScoreListTop;
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
    /**
     * 获取详情
     */
    toDetail(id) {
      this.$router.push({
        path: "/company/resultInfo",
        query: {
          resId: id,
          answerUserId: this.answerUserId,
          showSummary: this.QuestionnaireResult.questionnaire.showSummary,
        },
      });
    },
  },
};
</script>

<style scoped>
#Charts {
  width: 15vw;
  height: 15vw;
}

#Charts /deep/ canvas {
  width: 15vw !important;
  height: 15vw !important;
}

.content-wrap {
  background: #fff;
}

.content {
  width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 295px - 140px);
  padding: 30px 20px;
  background: url("../../assets/img/bg001.png") top;
  background-size: 100% 1050px;
}

.top-div {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.top-div .left-img {
  width: 24px;
  height: 24px;
  border: 5px solid #ebeefd;
  background: linear-gradient(90deg, #4db992, #81dfa7);
  box-shadow: 0px 3px 6px 0px rgba(18, 184, 149, 0.3);
  border-radius: 50%;
  margin-right: 10px;
}

.top-div .top-title {
  width: auto;
  height: 29px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}

/* .userTestRes-div .btn {
    width: 420px;
    height: 72px;
    background: linear-gradient(90deg, #03CDB4, #00A28E);
    box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.21);
    border-radius: 36px;
} */

.btn .text {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}

.addUser-div .w-18 {
  width: 80%;
}

.userTestRes-div {
  background: #fff;
  width: 80%;
  margin: 0 auto;
  height: auto;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 40px;
  padding: 30px 0;
}

.userTestRes-div .userTestRes-box {
  width: 90%;
}

.top-img {
  width: 100%;
  /* margin-bottom: 40px; */
}

.top-img div.title {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #019582;
  margin-left: 10px;
}

.top-1,
.top-2,
.top-3,
.top-4 {
  width: 100%;
  margin-bottom: 40px;
}

.top-1 .top-3 {
  margin-bottom: 0;
}

.top-1 .top-title1 {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.mark {
  cursor: pointer;
}

.top-1 .top-title1 .tip {
  font-size: 18px;
}

.top-1 .left-tongJi {
  width: 30%;
  margin-right: 5%;
}

.desc-1,
.desc-2 {
  margin-right: 20px;
  font-size: 20px;
  /* border-bottom: 1px dashed; */
}

.top-1 .left-tongJi img {
  width: 100%;
  height: 100%;
}

.top-1 .right-desc {
  width: 50%;
}

.top-1 .top-title1 .desc-1 {
  color: #019582;
}

.top-1 .top-title1 .desc-2 {
  color: #1a74e9;
}

.top-2 {
  min-height: 100px;
  background: #ffffff;
  border: 1px solid #6ec6bb;
  border-radius: 16px;
}

.top-2 .top2-desc {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  padding: 20px;
}

.top-2 .top2-desc .mark {
  color: #1a74e9;
  border-bottom: 1px dashed;
  margin: 0 5px;
}

.top-2 .top2-desc i {
  font-size: 26px;
}

.top-3 {
  width: calc(100% - 40px);
  background: #f5f6f6;
  border-radius: 2px;
  padding: 20px;
}

.top-3 .top3-title {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}

.top-3 .top3-desc {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.top-3 .top3-title i {
  font-size: 26px;
}

.userTestRes-div .active {
  border: 1px solid #00cbb5 !important;
}

.userTestRes-div .item-div:hover {
  border: 1px solid #00cbb5 !important;
}

.userTestRes-div .item-div {
  width: 27.5%;
  height: 112px;
  margin-right: 2%;
  margin-bottom: 20px;
  border: 1px solid #e3e6e6;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 30px;
  cursor: pointer;
  align-items: flex-start !important;
  padding: 10px 20px;
  position: relative;
}

.userTestRes-div .item-div:nth-child(3n) {
  margin-right: 0;
}

.userTestRes-div .userName {
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 500;
}

.userTestRes-div .item-div .addBtn {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #009583;
}

.userTestRes-div .userInfo {
  color: #999999;
}

.userTestRes-div .topDel-div {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #009583;
  cursor: pointer;
}

.content-center {
  width: 100%;
}

.share-box {
  font-size: 15px;
}

.share-box .img-icon {
  width: 16px;
  height: 16px;
  margin-right: 2px;
  margin-bottom: -2px;
}

.share-box .left {
  margin-right: 10px;
}

.content-center .res-box {
  width: 100%;
  border: 1px solid #01c1aa;
  border-radius: 15px;
  padding: 10px 5px 5px;
  margin: 10px 0;
}
.ring {
  width: 10px;
  height: 10px;
  border: 3px solid #01c1aa;
  box-shadow: 0px 2px 3px 0px #01c1aa;
  border-radius: 50%;
}
.flexlr {
  display: flex;
  justify-content: space-between;
}
.c1,
.c2 {
  width: 90%;
  margin: 5px auto;
  align-items: flex-start;
}

.left-title {
  width: 20%;
  text-align: right;
  font-size: 16px;
  padding-top: 10px;
  margin-right: 2%;
}

.c1 .score-box,
.c2 .score-box {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e6e6e6;
}

.score-right {
  width: 75%;
}

.score-box .desc {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}

.fen-box {
  border-radius: 15px;
  padding: 2px 10px;
  margin-left: 5px;
}

.fen {
  font-size: 10px;
}

.c3 {
  width: 92%;
  margin: 15px auto;
  align-items: flex-start;
}

.c3 .tips {
  font-size: 12px;
  color: #666666;
}

.color1 {
  color: #019582;
}

.bg1 {
  background: #d0f5f1;
}

.bg2 {
  background: #e3effe;
}

.bg3 {
  background: #01c1aa;
}

.border1 {
  border: 1px solid #1a74e9;
}

.btn {
  padding: 0 12px;
  font-size: 14px;
  color: #fff;
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.color2 {
  color: #1a74e9;
}

.shopDialog {
  width: 400px;
  /* height: 20vw; */
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}

.imgShopIcon {
  position: absolute;
  top: -30px;
  left: 50%;
  z-index: 3;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #01c4af;
  transform: translateX(-50%);
}

.imgleft {
  position: absolute;
  top: 150px;
  left: 15px;
  z-index: 3;
  width: 40px;
  height: 40px;
}

.imgRight {
  position: absolute;
  top: 150px;
  right: 15px;
  z-index: 3;
  width: 40px;
  height: 40px;
}

.imgShop {
  width: 225px;
  height: 225px;
  margin: 45px 0 35px;
  border: 1px solid #bef1eb;
  box-shadow: 0px 3px 6px 0px rgba(91, 182, 171, 0.3);
  border-radius: 10px;
  padding: 10px;
  margin-top: 60px;
}

.shopT {
  color: #666666;
  font-size: 14px;
  text-align: center;
}

.bRadius {
  border-radius: 50%;
}
</style>
